@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  @apply text-gray-50;
  @apply tracking-wide;
  @apply bg-slate-700;
}

h1 {
  @apply text-5xl;
  @apply my-5;
  @apply text-orange-500;
}

h2 {
  @apply text-xl;
  @apply my-4;
  @apply text-orange-500;
}

a:hover {
  @apply text-orange-500;
  @apply underline;
}

.markdown {
  p, ul, h2 {
    @apply leading-7;
    @apply my-4;
  }

  ul {
    @apply list-disc list-inside;
  }
}

.main-menu {
  li {
    @apply mb-4;
    @apply text-right;
  }
}

.homepage {
  h1 {
    @apply text-4xl;
  }

  .main-menu {
    @apply flex;
    @apply justify-between;

    li {
      @apply mr-3;
    }

    a {
      @apply inline-block;
      @apply py-2;
      @apply px-4;
    }
  }
}

// forms
form fieldset {
  @apply border-2 border-gray-300;
  @apply p-4;
  @apply rounded-lg;
  @apply my-4;

  div {
    @apply mb-0;
  }
}
